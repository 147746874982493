<!--
 * @Descripttion: 
 * @FilePath: /azg/src/views/register/store/components/Agreement.vue
 * @Author: 张兴业
 * @Date: 2020-12-10 21:25:15
 * @LastEditors: 张兴业
 * @LastEditTime: 2020-12-10 22:24:35
-->

<template>
  <div class="agreement">
    <img
      v-if="agree === '1'"
      src="@/assets/register/icon_selected_fill.svg"
      alt=""
      class="select_img left"
      @click.stop="unselectedAgreement"
    />
    <img
      v-if="agree === '0'"
      src="@/assets/register/icon_selected_no.svg"
      alt=""
      class="select_img left"
      @click.stop="selectedAgreement"
    />
    <div class="agree_text">
      <span class="agree_text_1">我已阅读并同意</span>
      <el-link type="primary">用户协议</el-link>
      <span>、</span>
      <el-link type="primary">隐私政策</el-link>
    </div>
  </div>
</template>

<script>
export default {
  props: ["value"],
  data() {
    return {
      agree: this.value
    };
  },
  methods: {
    selectedAgreement() {
      this.agree = "1";
      this.$emit("input", "1");
    },
    unselectedAgreement() {
      this.agree = "0";
      this.$emit("input", "0");
    }
  }
};
</script>

<style lang="less" scoped>
.agreement {
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  line-height: 20px;

  .select_img {
    width: 18px;
    height: 18px;
    display: block;
    margin: auto 0;
  }
  .agree_text {
    margin: auto 0;
  }
  .agree_text_1 {
    margin-top: 1px;
    margin-left: 4px;
    font-weight: 500;
    vertical-align: middle;
  }
}
</style>
